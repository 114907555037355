define("discourse/plugins/discourse-follow/discourse/components/follow-statistic", ["exports", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _i18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FollowStatistic = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="follow-statistic">
      <dt>{{i18n @label}}</dt><dd>{{@total}}</dd>
    </div>
  
  */
  {
    "id": "KFBm6Rry",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"follow-statistic\"],[12],[1,\"\\n    \"],[10,\"dt\"],[12],[1,[28,[32,0],[[30,1]],null]],[13],[10,\"dd\"],[12],[1,[30,2]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@label\",\"@total\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-follow/discourse/components/follow-statistic.js",
    "scope": () => [_i18n.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = FollowStatistic;
});